import { accountService } from "../_services";
import axios from "axios";

export const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete,
  postFile,
  getFile,
};

function get(url, body) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(url),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", ...authHeader(url) },
    credentials: "include",
    body: JSON.stringify(body),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function getFile(url, body) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(url),
  };

  return fetch(url, requestOptions).then((res) => res.blob());
}

async function postFile(url, file, body) {
  const data = new FormData();
  data.append("file", file.file);
  if (body) data.append("skipFirstRow", body.skipFirstRow);

  const res = await axios.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      ...authHeader(url),
    },
  });

  return res.data;
}

function put(url, body) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", ...authHeader(url) },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(url),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const user = accountService.userValue;
  const isLoggedIn = user && user.jwtToken;

  const isApiUrl = url.startsWith(process.env.REACT_APP_SERVER_URL);

  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${user.jwtToken}` };
  } else {
    return {};
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].includes(response.status) && accountService.userValue) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        accountService.logout();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
