import { fetchWrapper} from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/games";

export const gameService = {
    create,
    getAll,
    getById,
    update,
    startStopGame
};

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}


function getAll(user_id) {
    return fetchWrapper.get(`${baseUrl}/get-games/${user_id}`);
}


function getById(game_id) {
    return fetchWrapper.get(`${baseUrl}/get-one-game/${game_id}`);
}

function update(params,id){
    return fetchWrapper.put(`${baseUrl}/${id}`, params)
}

function startStopGame(id){
    return fetchWrapper.put(`${baseUrl}/startStopGame/${id}`)
}