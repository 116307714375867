import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarProfile from './TopbarProfile';
import TopbarLanguage from './TopbarLanguage';
import TopbarMenuLink from './TopbarMenuLink'

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };


  render() {
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
            <Link className="ml-3" to="/">Home</Link>
            <Link className="ml-3" to="/questions">Baza pytań</Link>

          <div className="topbar__right">

            <TopbarLanguage />
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
