import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';


class UserJoin extends Component {
    constructor(props) {
        super()
        this.state = { android_user: false, showModal: false, userRejectedApp: false }
    }

    componentDidMount = () => {
        if (navigator.userAgent.toLowerCase().indexOf("android") !== -1) {
            this.setState({ android_user: true, showModal: true })
        }

    }

    render() {
        const { t } = this.props
        return (
            <>
                <div className="text-center">
                    {this.state.android_user && this.state.showModal?
                        <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }}>
                            <Modal.Header>
                                <Modal.Title>
                                    {t('game.open-in-app')}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="d-flex flex-row justify-content-around">
                                    {!this.state.userRejectedApp ?
                                        <>
                                            <a className="btn btn-primary" href={`intent://game?id=${this.props.match.params.id}#Intent;scheme=gameapp;action=android.intent.action.VIEW;end;`} target="_self">Tak</a>
                                            <button className="btn btn-danger" onClick={() => this.setState({ userRejectedApp: true })}>Nie</button>
                                        </>
                                        :
                                        <h4>{t('game.type-in-app')}: <b>{this.props.match.params.id}</b></h4>}
                                </div>
                            </Modal.Body>
                        </Modal>
                        :
                        <h2>{t('game.type-in-app')}: <b>{this.props.match.params.id}</b></h2>
                    }
                </div>
            </>

        )
    }
}

export default (withTranslation("common"))(UserJoin)
