import React from 'react'
import Radio from '@material-ui/core/Radio';

export default function AnswerInput({ index, answer_value, answer_change, select_change, selected_input }) {
    return (
        <div className="d-flex flex-row align-items-center" style={{ "padding": "15px 0 15px 15px" }}>
            <Radio
                name="correct_answer"
                value={index}
                checked={selected_input === index}
                onChange={() => { select_change(index) }}
                style={{ "marginRight": "10px" }}
            />

            <input
                required
                type="text"
                className="form-control"
                placeholder={`Odpowiedź ${index}...`}
                value={answer_value}
                onChange={(e) => answer_change(e.target.value)}
            />
        </div>
    )
}
