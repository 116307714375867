import { fetchWrapper} from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/categories";

export const categoryService = {
    create,
    getCategories
};

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}

function getCategories() {
    return fetchWrapper.get(baseUrl);
}
