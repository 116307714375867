import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap'
import Categories from './Categories'
import { categoryService, alertService } from '~root/_services'

class QuestionBase extends Component {
    constructor() {
        super()
        this.state = { categories: [] }
    }

    componentDidMount = () => {
        this.getCategories()
    }

    getCategories = () => {
        categoryService.getCategories()
            .then(res => {
                this.setState({ categories: res })
            })
            .catch(err => {
                alertService.error(err)
            })
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <Categories
                                    data={this.state.categories}
                                    reloadCategories={this.getCategories}
                                    history={this.props.history}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default QuestionBase;
