import React, { useEffect, useState } from 'react'
import QuestionForm from './QuestionForm'
import { questionService, alertService, mediaService } from '~root/_services'
import { Row } from 'reactstrap'

export default function Questions({ match }) {
    const [questions, setQuestions] = useState([]);

    function getQuestions() {
        questionService.getAllFromRound(match.params.id)
            .then(res => {
                setQuestions(res)
            })
            .catch(err => {
                alertService.error(err)
            })
    }

    useEffect(() => {
        getQuestions()
    }, [])


    async function addNewQuestion(params, attachments) {
        if (attachments.length > 0) {
            mediaService.create(attachments[0])
                .then(res => {
                    questionService.create({ category: match.params.id, media: res, ...params })
                        .then(() => {
                            getQuestions()
                        })
                        .catch(err => {
                            alertService.error(err)
                        })
                })
                .catch(err => {
                    alertService.error(err)
                })
        }
        else {
            questionService.create({ category: match.params.id, ...params })
                .then(() => {
                    getQuestions()
                })
                .catch(err => {
                    alertService.error(err)
                })
        }
    }

    async function updateQuestion(id, params, attachments) {
        if (attachments.length > 0) {
            mediaService.create(attachments[0])
                .then(res => {
                    questionService.update(id, { media: res, ...params })
                        .then(() => {
                            getQuestions()
                        })
                        .catch(err => {
                            alertService.error(err)
                        })
                })
                .catch(err => {
                    alertService.error(err)
                })
        }
        else {
            questionService.update(id, params)
                .then(() => {
                    getQuestions()
                })
                .catch(err => {
                    alertService.error(err)
                })
        }
    }

    function deleteQuestion(id) {
        questionService.deleteQuestion(id)
            .then(() => getQuestions())
            .catch(err => {
                alertService.error(err)
            })
    }

    async function getFile(id) {
        mediaService.getFile(id)
            .then(res => {
                return res
            })
            .catch(err => {
                alertService.error(err)
            })
    }

    function deleteAttachment(id) {
        mediaService.deleteAttachment(id)
            .then(() => getQuestions())
            .catch(err => {
                alertService.error(err)
            })
    }

    return (
        <Row style={{ "margin": "0" }}>
            {
                questions.map((item) => {
                    return (
                        <QuestionForm
                            key={"question_" + item.id}
                            question={item}
                            updateQuestion={updateQuestion}
                            deleteQuestion={deleteQuestion}
                            getFile={getFile}
                            deleteAttachment={deleteAttachment}
                        />
                    )
                })}

            <QuestionForm
                roundID={match.params.id}
                addQuestion={addNewQuestion}
                getFile={() => { return [] }}
                deleteAttachment={() => { }}
            />
        </Row>
    )
}
