import { fetchWrapper} from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/challenges";

export const challengeService = {
    get
};

function get() {
    return fetchWrapper.get(`${baseUrl}/names`);
}

