import React, { useState } from "react";
import TableComponent from "./Table";
import Modal from "react-bootstrap/Modal";
import { categoryService, alertService, questionService } from "~root/_services";

function Categories({ data, reloadCategories, history }) {
    const [visible, changeVisibility] = useState(false);
    const [categoryName, changeCategoryName] = useState("");
    const [skipFirstRow, setSkip] = useState(false);
    const [file, setFile] = useState();

    function saveCategory() {
        categoryService
            .create({ content: categoryName.trim() })
            .then((res) => {
                changeVisibility(false);
                changeCategoryName("");
                reloadCategories();
            })
            .catch((err) => {
                changeVisibility(false);
                changeCategoryName("");
                alertService.error(err);
            });
    }

    const handleFileSelect = (e) => {
        const file = e.target.files[0];

        if (!file) return;

        const file_ext = file.name.split(".").pop();

        if (file_ext !== "xlsx") {
            alertService.error("Akceptowane rozszerzenie to xlsx");
            e.preventDefault();
            e.target.value = "";
        }

        setFile(file);
    };

    const handleUpload = () => {
        if(!file){
            alertService.error("Załącz plik")
            return;
        }

        questionService.uploadQuestions({file, skipFirstRow})
        .then(()=>{
            alertService.success("Pytania załadowane poprawnie")
            reloadCategories()
        })
        .catch((err)=>{
            alertService.error("Błąd importu pytań: "+err)
        })
    }

    return (
        <div>
            <TableComponent
                data={data}
                showModal={() => changeVisibility(true)}
                history={history}
            />

            <h3>Importuj pytania z pliku</h3>
            <div
                className="d-flex flex-row align-items-center"
                style={{ padding: 10, paddingLeft: 0 }}
            >
                <label className="m-0 mr-3">Pomiń pierwszy wiersz</label>
                <input
                    type="checkbox"
                    value={skipFirstRow}
                    onChange={() => setSkip((val) => !val)}
                    style={{ cursor: "pointer" }}
                />
            </div>
            <input type="file" accept=".xlsx" onChange={handleFileSelect} />
            <div className="pt-3">
                <button className="btn btn-primary" onClick={handleUpload}>Importuj</button>
            </div>
            <Modal show={visible} onHide={() => changeVisibility(false)}>
                <Modal.Header>
                    <Modal.Title>Nowa kategoria</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-column justify-content-between">
                        <input
                            className="form-control"
                            style={{ marginBottom: "20px" }}
                            placeholder="Nazwa kategorii..."
                            type="text"
                            value={categoryName}
                            onChange={(e) => changeCategoryName(e.target.value)}
                        />
                        <div className="d-flex flex-row align-self-end">
                            <button
                                className="btn btn-danger"
                                onClick={() => {
                                    changeVisibility(false);
                                    changeCategoryName("");
                                }}
                            >
                                Anuluj
              </button>
                            <button className="btn btn-primary" onClick={saveCategory}>
                                Zapisz
              </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Categories;
