import React, { Component } from 'react';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { VideoLibrary } from '@material-ui/icons';

const MAX_FILE_SIZE_MB = 25
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024 //25 MB

class AttachmentsDropzone extends Component {
    handlePreviewIcon = (fileObject, classes) => {
        const { type } = fileObject.file
        const iconProps = {
            className: classes.image,
        }

        if (type.startsWith("video/")) return <VideoLibrary {...iconProps} />
        if (type.startsWith("image/")) return <img src={fileObject.data} {...iconProps} />
    }
    render() {
        return (
            <DropzoneAreaBase
                dropzoneText="Upuść plik lub naciśnij aby wybrać"
                acceptedFiles={["image/jpg", "image/png", "image/jpeg", "image/gif", "video/mp4", "video/mov", "video/avi"]}
                filesLimit={1}
                dropzoneClass="file-dropzone"
                showPreviews={true}
                showPreviewsInDropzone={false}
                previewText="Wybrany plik"
                fileObjects={this.props.attachedFiles}
                onAdd={(fileObjs) => { this.props.addFile(fileObjs) }}
                onDelete={this.props.emptyAttachments}
                maxFileSize={MAX_FILE_SIZE}
                getPreviewIcon={this.handlePreviewIcon}
                getFileAddedMessage={(fileName) => { return `Plik ${fileName} został dodany` }}
                getDropRejectMessage={(rejectedFile, acceptedFiles) => {
                    let message = ""
                    if (acceptedFiles.indexOf(rejectedFile.type) == -1)
                        message += `Rozszerzenie pliku ${rejectedFile.name} jest nieprawidłowe. `

                    if (rejectedFile.size > MAX_FILE_SIZE)
                        message += `Plik jest zbyt duży. Maksymalny rozmiar pliku to ${MAX_FILE_SIZE_MB}MB.`

                    return message;
                }}
            />
        );
    }
}

export default AttachmentsDropzone;
