import React, { Component } from 'react'
import Confirm from '~shared/components/Confirm'

const baseUrl = process.env.REACT_APP_SERVER_URL + "/media";

export class AttachmentView extends Component {
    constructor() {
        super()
        this.state = { show_confirm: false }
    }

    deleteAttachment = () => {
        this.props.deleteAttachment(this.props.id)

        this.setState({ show_confirm: false })
    }

    render() {
        return (
            <div>
                <Confirm
                    question="Czy chcesz usunąć załącznik?"
                    description="Tej operacji nie da się cofnąć"
                    onConfirm={this.deleteAttachment}
                    onReject={() => this.setState({ show_confirm: false })}
                    show={this.state.show_confirm}
                />
                <p style={{ "fontSize": "0.9375rem" }}>Dodane załączniki:</p>
                <div className="preview-container">
                    {this.props.type == "image" ?
                        <img src={baseUrl + "/" + this.props.id} />
                        :
                        <video controls src={baseUrl + "/" + this.props.id}></video>
                    }

                    <div className="delete-button" onClick={() => this.setState({ show_confirm: true })}>
                        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                            <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z">

                            </path>
                        </svg>
                    </div>
                </div>
            </div>
        )
    }
}

export default AttachmentView
