import React, { Component } from 'react'
import { Col, Card, CardBody } from 'reactstrap'
import AnswerInput from './AnswerInput'
import Button from '@material-ui/core/Button';
import DeleteIcon from '~root/shared/icons/DeleteIcon'
import Checkbox from '@material-ui/core/Checkbox';
import AttachmentsDropzone from './AttachmentsDropzone'
import AttachmentView from './AttachmentView'
import Confirm from '~shared/components/Confirm'

export class QuestionForm extends Component {
    constructor(props) {
        super()

        this.state = {
            question: props.question ? props.question.question : "",
            answer_1: props.question ? props.question.answer_1 : "",
            answer_2: props.question ? props.question.answer_2 : "",
            answer_3: props.question ? props.question.answer_3 : "",
            answer_4: props.question ? props.question.answer_4 : "",
            selected: props.question ? props.question.selected : 1,
            withAttachments: props.question ? !!props.question.media : false,
            attachments: [],
            show_confirm: false
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()

        const question_object = {
            content: this.state.question,
            answers: [
                { content: this.state.answer_1, correct: this.state.selected === 1 },
                { content: this.state.answer_2, correct: this.state.selected === 2 },
                { content: this.state.answer_3, correct: this.state.selected === 3 },
                { content: this.state.answer_4, correct: this.state.selected === 4 }
            ]
        }
        if (this.props.question) {
            this.props.updateQuestion(this.props.question.id, question_object, this.state.attachments)
        }
        else {
            this.props.addQuestion(question_object, this.state.attachments)
            this.setState({ question: "", answer_1: "", answer_2: "", answer_3: "", answer_4: "", selected: 1 })
        }

        this.resetAttachments()
    }

    addFile = (fileObj) => {
        this.setState({ attachments: fileObj })
    }

    emptyAttachments = () => {
        this.setState({ attachments: [] })
    }

    resetAttachments = () => {
        this.emptyAttachments()
        this.setState({ withAttachments: false })
    }

    render() {
        return (
            <Col sm={12} md={6} lg={4} xl={3}>
                <Card>
                    <CardBody>
                        {this.props.question ?
                            <div className="upper-top-icon-container">
                                <Confirm
                                    question="Czy na pewno chcesz usunąć pytanie?"
                                    description="Tej operacji nie da się cofnąć"
                                    onConfirm={() => { this.props.deleteQuestion(this.props.question.id) }}
                                    onReject={() => this.setState({ show_confirm: false })}
                                    show={this.state.show_confirm}
                                />
                                <DeleteIcon animated={true} clickHandle={() => { this.setState({ show_confirm: true }) }} />
                            </div>
                            : null}
                        <form onSubmit={this.handleSubmit} style={{ "marginTop": "20px" }}>
                            <input
                                type="text"
                                className="form-control"
                                placeholder={"Pytanie..."}
                                required
                                value={this.state.question}
                                onChange={(e) => this.setState({ question: e.target.value })}
                            />
                            <div>
                                <AnswerInput
                                    index={1}
                                    selected_input={this.state.selected}
                                    answer_value={this.state.answer_1}
                                    answer_change={(value) => this.setState({ answer_1: value })}
                                    select_change={(value) => this.setState({ selected: value })}
                                />
                                <AnswerInput
                                    index={2}
                                    selected_input={this.state.selected}
                                    answer_value={this.state.answer_2}
                                    answer_change={(value) => this.setState({ answer_2: value })}
                                    select_change={(value) => this.setState({ selected: value })}
                                />
                                <AnswerInput
                                    index={3}
                                    selected_input={this.state.selected}
                                    answer_value={this.state.answer_3}
                                    answer_change={(value) => this.setState({ answer_3: value })}
                                    select_change={(value) => this.setState({ selected: value })}
                                />
                                <AnswerInput
                                    index={4}
                                    selected_input={this.state.selected}
                                    answer_value={this.state.answer_4}
                                    answer_change={(value) => { this.setState({ answer_4: value }) }}
                                    select_change={(value) => this.setState({ selected: value })}
                                />
                            </div>
                            {(this.props.question && !this.props.question.media) || !this.props.question ?
                                <div className="d-flex flex-row align-items-center">
                                    <p style={{ "fontSize": "0.9375rem" }}>Załącznik do pytania</p>
                                    <Checkbox checked={this.state.withAttachments} onChange={() => { this.setState({ withAttachments: !this.state.withAttachments }) }} />
                                </div>
                                : null}

                            {(this.state.withAttachments && this.props.question && !this.props.question.media) || (!this.props.question && this.state.withAttachments) ?
                                <AttachmentsDropzone
                                    attachedFiles={this.state.attachments}
                                    addFile={this.addFile}
                                    emptyAttachments={this.emptyAttachments}
                                />
                                : null
                            }

                            {this.props.question && this.props.question.media ?
                                <AttachmentView
                                    id={this.props.question.media._id}
                                    type={this.props.question.media.type}
                                    deleteAttachment={(id) => {
                                        this.props.deleteAttachment(id)
                                        this.resetAttachments()
                                    }}
                                />
                                : null
                            }

                            <Button
                                className="d-flex"
                                variant="outlined"
                                color="secondary"
                                style={{ "margin": "15px 0 0 auto" }}
                                type="submit"
                            >
                                {this.props.question ?
                                    "Zapisz pytanie"
                                    :
                                    "Dodaj pytanie"
                                }
                            </Button>
                        </form>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

export default QuestionForm
