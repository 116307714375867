import { fetchWrapper} from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/media";

export const mediaService = {
    create,
    getFile,
    deleteAttachment
}

function create(file) {
    return fetchWrapper.postFile(`${baseUrl}/upload`, file);
}

function getFile(id){
    return fetchWrapper.getFile(`${baseUrl}/${id}`);
}

function deleteAttachment(id){
    return fetchWrapper.delete(`${baseUrl}/${id}`);
}