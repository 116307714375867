import { fetchWrapper } from "~root/_helpers";

const baseUrl = process.env.REACT_APP_SERVER_URL + "/questions";

export const questionService = {
  create,
  update,
  deleteQuestion,
  getAllFromRound,
  uploadQuestions,
};

function create(params) {
  return fetchWrapper.post(baseUrl, params);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function deleteQuestion(id) {
  return fetchWrapper.delete(`${baseUrl}/${id}`);
}

function getAllFromRound(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function uploadQuestions({ file, skipFirstRow }) {
  return fetchWrapper.postFile(
    `${baseUrl}/readQuestions`,
    { file },
    { skipFirstRow }
  );
}
