import React, { Component, Fragment} from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '~root/scss/app.scss';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { config as i18nextConfig } from '~root/translations';
import {history} from '~root/_helpers/history'

i18n.init(i18nextConfig);

class App extends Component {

  constructor() {
    super();
    this.state = {
      loading: true,
      loaded: false,
    };

  }

  componentDidMount() {
      this.setState({ loading: false });
      setTimeout(() => this.setState({ loaded: true }), 500);
  }
  render() {

    const { loaded, loading } = this.state;
    return (
      <Provider store={store}>
        <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <ScrollToTop>
            <Fragment>
              {!loaded
                && (
                  <div className={`load${loading ? '' : ' loaded'}`}>
                    <div className="load__icon-wrap">
                      <svg className="load__icon">
                        <path fill="#87CE32" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                      </svg>
                    </div>
                  </div>
                )
              }
              <div>
                <Router history={history}/>
              </div>
            </Fragment>
          </ScrollToTop>
          </I18nextProvider>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
