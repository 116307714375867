import React, { Component } from "react";
import HeartOutlineIcon from "mdi-react/HeartOutlineIcon";
import EarthIcon from "mdi-react/EarthIcon";
import Rounds from "./Rounds";
import { withTranslation } from "react-i18next";
import { alertService } from "~root/_services";

class RoomForm extends Component {
  constructor(props) {
    super();

    this.state = {
      upload: false,
      lives: 0,
      lives_number: "",
      challenge: undefined,
      lang: "pl",
    };
  }
  componentDidUpdate = (prevProps) => {
    if (
      this.props.current !== prevProps.current &&
      this.props.current !== null
    ) {
      this.setState({
        lives: this.props.current.lives,
        lives_number: this.props.current.lives_number
          ? this.props.current.lives_number
          : "",
        lang: this.props.current.lang,
        challenge:this.props.current.challenge
      });
      return;
    } else if (this.props.visible !== prevProps.visible) {
      this.setState({
        lives: 0,
        lives_number: "",
        lang: "pl",
        challenge: undefined,
      });
      return;
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();

    if(this.state.challenge===undefined){
        alertService.error("Challenge is required")
        return
    }


    let form = {
      lives: this.state.lives,
      lives_number:
        this.state.lives_number === "" || this.state.lives === 1
          ? null
          : this.state.lives_number,
      lang: this.state.lang,
      challenge:this.state.challenge
    };

    this.props.handleSubmit(form);
  };

  backToForm = () => {
    this.setState({ upload: false });
    this.props.handleShowUpload(false);
  };

  render() {
    const { t, challenges } = this.props;
    return (
      <>
        {!this.props.showUpload ? (
          <form
            acceptCharset="UTF-8"
            method="post"
            onSubmit={(e) => {
              this.handleSubmit(e);
            }}
          >
            {/* lives count */}
            <div className="form__form-group" style={{ textAlign: "left" }}>
              <span className="form__form-group-label text-left">
                {t("game.lives-count")}
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon game_icon_container">
                  <HeartOutlineIcon />
                </div>
                <select
                  name="lives"
                  as="select"
                  className="form-control"
                  value={this.state.lives}
                  onChange={(e) => {
                    this.setState({ lives: parseInt(e.target.value) });
                  }}
                >
                  <option value={0}>{t("game.live-specific")}</option>
                  <option value={1}>{t("game.live-infinity")}</option>
                </select>
                {this.state.lives === 0 ? (
                  <div>
                    <input
                      name="lives-count"
                      value={this.state.lives_number}
                      className="form-control"
                      placeholder={t("game.count")}
                      required
                      type="number"
                      min={1}
                      onChange={(e) => {
                        this.setState({ lives_number: e.target.value });
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <div className="invalid-feedback text-left">
                {t("game.name-cannot-be-blank")}
              </div>
            </div>

            {/* language */}
            <div className="form__form-group" style={{ textAlign: "left" }}>
              <span className="form__form-group-label text-left">
                {t("game.language")}
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon game_icon_container">
                  <EarthIcon />
                </div>
                <select
                  name="role"
                  as="select"
                  className="form-control"
                  value={this.state.lang}
                  onChange={(e) => {
                    this.setState({ lang: e.target.value });
                  }}
                >
                  <option value={"pl"}>{t("game.language-pl")}</option>
                  <option value={"en"}>{t("game.language-en")}</option>
                </select>
              </div>
              <div className="invalid-feedback text-left">
                {t("game.name-cannot-be-blank")}
              </div>
            </div>

            {/* challenge */}
            <div className="form__form-group" style={{ textAlign: "left" }}>
              <span className="form__form-group-label text-left">
                Challenge
              </span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon game_icon_container"></div>
                <select
                  name="role"
                  as="select"
                  className="form-control"
                  value={this.state.challenge}
                  onChange={(e) => {
                    this.setState({ challenge: e.target.value });
                  }}
                >
                  <option value={undefined}></option>

                  {challenges.map((item) => {
                    return <option value={item._id}>{item.title}</option>;
                  })}
                </select>
              </div>
              <div className="invalid-feedback text-left">
                {t("game.name-cannot-be-blank")}
              </div>
            </div>

            <div className="mt-4">
              {this.props.create && !this.props.update ? (
                <input
                  type="submit"
                  name="commit"
                  value={t("game.create-game")}
                  className="create-only btn btn-orange btn-block"
                  data-disable-with={t("game.create-game")}
                />
              ) : (
                <input
                  type="submit"
                  name="commit"
                  value={t("game.update-game")}
                  className="update-only btn btn-orange btn-block"
                  data-disable-with={t("game.update-game")}
                />
              )}
            </div>
          </form>
        ) : (
          <Rounds
            backToForm={this.backToForm}
            slides={this.state.slides}
            getSlides={this.getSlides}
            room_id={this.props.current.id}
          />
        )}
      </>
    );
  }
}

export default withTranslation("common")(RoomForm);
