import React, { Component } from 'react'
import CounterIcon from 'mdi-react/CounterIcon';
import { withTranslation } from 'react-i18next';
import CommentQuestionOutlineIcon from 'mdi-react/CommentQuestionOutlineIcon'
import AccountArrowRightOutlineIcon from 'mdi-react/AccountArrowRightOutlineIcon'
import AccountClockOutlineIcon from 'mdi-react/AccountClockOutlineIcon'
import ClockTimeFourOutlineIcon from 'mdi-react/ClockTimeFourOutlineIcon'
import { alertService, roundService, categoryService } from '~root/_services'

class RoundForm extends Component {
    constructor() {
        super()
        this.state = {
            title: "",
            points_condition: 0,
            question_form: 0,
            next_round_condition: 0,
            max_players_count: undefined,
            required_score: undefined,
            end_round_condition: 0,
            round_timeout: undefined,
            time_for_answer: undefined,
            question_category: "",
            question_count: "",
        }
    }

    componentDidMount = () => {
        if (this.props.current_round){
            this.setState(this.props.current_round)
            this.setState({question_category:this.props.current_round.question_category._id})
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (!this.props.update) {
            roundService.create({ gameID: this.props.gameID, ...this.state })
                .then(() => {
                    this.props.getRounds(this.props.gameID)
                    this.props.closeModal()
                })
                .catch(err => {
                    alertService.error(err)
                })
        }
        else {
            roundService.update(this.props.current_round._id, { gameID: this.props.current_round.gameID, ...this.state })
                .then(() => {
                    this.props.getRounds(this.props.gameID)
                    this.props.closeModal()
                })
                .catch(err => {
                    alertService.error(err)
                })
        }
    }

    render() {
        const { t } = this.props
        let input_round_condition
        if (this.state.next_round_condition === 0) {
            input_round_condition = <div>
                <input type="number" min={1} defaultValue={this.state.max_players_count} className="form-control"
                    onChange={(e) => this.setState({ max_players_count: parseInt(e.target.value) })}
                    placeholder={t('round.entrance.max-players-placeholder')} name="max-players-count"
                />
            </div>
        }
        else if (this.state.next_round_condition === 1) {
            input_round_condition = <div>
                <input type="number" min={1} defaultValue={this.state.required_score} className="form-control"
                    onChange={(e) => this.setState({ required_score: parseInt(e.target.value) })}
                    placeholder={t('round.entrance.required_score-placeholder')} name="required-score"
                />
            </div>
        }

        return (
            <>
                {!this.props.showUpload ?
                    <form acceptCharset="UTF-8" method="post" onSubmit={(e) => {
                        this.handleSubmit(e)
                    }}>
                        {/* round title */}
                        <div className="form__form-group" style={{ "textAlign": "left" }}>
                            <span className="form__form-group-label text-left">Nazwa rundy</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-icon game_icon_container">
                                    <AccountArrowRightOutlineIcon />
                                </div>
                                <input type="text" value={this.state.title} className="form-control"
                                    onChange={(e) => this.setState({ title: e.target.value })}
                                    placeholder="Nazwa rundy..." name="round-title"
                                />
                            </div>
                        </div>

                        {/* point condition */}
                        <div className="form__form-group" style={{ "textAlign": "left" }}>
                            <span className="form__form-group-label text-left">{t('round.point-condition')}</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-icon game_icon_container">
                                    <CounterIcon />
                                </div>
                                <select name="get-point-condition" as="select" className="form-control" value={this.state.points_condition} onChange={(e) => {
                                    this.setState({ points_condition: parseInt(e.target.value) })
                                }}>
                                    <option value={0}>{t('round.point.faster')}</option>
                                    <option value={1}>{t('round.point.first')}</option>
                                    <option value={2}>{t('round.point.negative')}</option>
                                    <option value={3}>{t('round.point.bet')}</option>
                                    <option value={4}>{t('round.point.eliminate')}</option>
                                    <option value={5}>{t('round.point.live')}</option>
                                </select>
                            </div>
                        </div>

                        {/* question form */}
                        <div className="form__form-group" style={{ "textAlign": "left" }}>
                            <span className="form__form-group-label text-left">{t('round.question-form')}</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-icon game_icon_container">
                                    <CommentQuestionOutlineIcon />
                                </div>
                                <select name="question-form" as="select" className="form-control" value={this.state.question_form} onChange={(e) => {
                                    this.setState({ question_form: parseInt(e.target.value) })
                                }}>
                                    <option value={0}>{t('round.question.text')}</option>
                                    <option value={1}>{t('round.question.photo')}</option>
                                    <option value={2}>{t('round.question.video')}</option>
                                </select>
                            </div>
                        </div>

                        {/* enter round condition */}
                        <div className="form__form-group" style={{ "textAlign": "left" }}>
                            <span className="form__form-group-label text-left">{t('round.entrance-condition')}</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-icon game_icon_container">
                                    <AccountArrowRightOutlineIcon />
                                </div>
                                <select name="enter-round-condition" as="select" className="form-control" value={this.state.next_round_condition} onChange={(e) => {
                                    this.setState({ next_round_condition: parseInt(e.target.value) })
                                }}>
                                    <option value={0}>{t('round.entrance.max-players')}</option>
                                    <option value={1}>{t('round.entrance.score')}</option>
                                </select>
                                {input_round_condition}
                            </div>
                        </div>

                        {/* end round condition */}
                        <div className="form__form-group" style={{ "textAlign": "left" }}>
                            <span className="form__form-group-label text-left">{t('round.end-round-condition')}</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-icon game_icon_container">
                                    <AccountClockOutlineIcon />
                                </div>
                                <select name="end-round-condition" as="select" className="form-control" value={this.state.end_round_condition} onChange={(e) => {
                                    this.setState({ end_round_condition: parseInt(e.target.value) })
                                }}>
                                    <option value={0}>{t('round.end.out-of-time')}</option>
                                    <option value={1}>{t('round.end.first')}</option>
                                    <option value={2}>{t('round.end.all')}</option>
                                </select>
                                {this.state.end_round_condition === 0 ?
                                    <div>
                                        <input type="number" min={1} defaultValue={this.state.round_timeout} className="form-control"
                                            onChange={(e) => this.setState({ round_timeout: parseInt(e.target.value) })}
                                            placeholder={t('round.end.out-of-time-placeholder')} name="round-timeout"
                                        />
                                    </div>
                                    : null}
                            </div>
                        </div>

                        {/* Time to answer */}
                        <div className="form__form-group" style={{ "textAlign": "left" }}>
                            <span className="form__form-group-label text-left">{t('round.time-to-answer')}</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-icon game_icon_container">
                                    <ClockTimeFourOutlineIcon />
                                </div>
                                <input type="number" min={1} defaultValue={this.state.time_for_answer} className="form-control"
                                    onChange={(e) => this.setState({ time_for_answer: parseInt(e.target.value) })}
                                    placeholder={t('round.answer-time-placeholder')} name="time-for-answer"
                                />
                            </div>
                        </div>


                        {/* questions count */}
                        <div className="form__form-group" style={{ "textAlign": "left" }}>
                            <span className="form__form-group-label text-left">{t('round.question-count')}</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-icon game_icon_container">
                                    <CounterIcon />
                                </div>
                                <input type="number" min={1} defaultValue={this.state.question_count} className="form-control"
                                    onChange={(e) => this.setState({ question_count: parseInt(e.target.value) })}
                                    placeholder={t('round.question-count-placeholder')} name="question-count"
                                />
                            </div>
                        </div>

                        {/* category */}
                        <div className="form__form-group" style={{ "textAlign": "left" }}>
                            <span className="form__form-group-label text-left">{t('round.question-category')}</span>
                            <div className="form__form-group-field">
                                <div className="form__form-group-icon game_icon_container">
                                    <AccountClockOutlineIcon />
                                </div>
                                <select name="question-category" as="select" className="form-control" value={this.state.question_category} onChange={(e) => {
                                    this.setState({ question_category: e.target.value })
                                }}>
                                    <option value=""></option>
                                    {this.props.categories.map(item => {
                                        return <option key={"option_"+item._id} value={item._id}>{item.content}</option>
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className="mt-4">
                            {this.props.create && !this.props.update ?
                                <input type="submit" name="commit" value={t('round.create-round')} className="create-only btn btn-orange btn-block" data-disable-with={t('game.create-game')} />
                                :
                                <input type="submit" name="commit" value={t('round.update-round')} className="update-only btn btn-orange btn-block" data-disable-with={t('game.update-game')} />
                            }
                        </div>
                    </form>
                    : null
                }
            </>
        )
    }
}

export default (withTranslation('common'))(RoundForm)
