import React from 'react';
import { Table } from 'reactstrap'
import AddIcon from '~shared/icons/AddIcon'

function TableComponent({ data, showModal, history }) {
    return (
        <Table className="table--bordered table_pointered" responsive hover>
            <tbody>
                {data.map(item => {
                    return (
                        <tr key={"category"+item._id} onClick={() => history.push("/questions/" + item._id)}>
                            <td>{item.content}</td>
                        </tr>
                    )
                })}
                <tr onClick={showModal}>
                    <td className="d-flex flex-row align-items-center">
                        <AddIcon clickHandle={() => { }} animated={false} />
                        <span style={{ "marginLeft": "10px" }}>Dodaj nową kategorię</span>
                    </td>
                </tr>
            </tbody>
        </Table>
    );
}

export default TableComponent;