import { fetchWrapper} from '~root/_helpers';

const baseUrl = process.env.REACT_APP_SERVER_URL+"/rounds";

export const roundService = {
    create,
    update,
    getRounds,
    remove
};

function create(params) {
    return fetchWrapper.post(baseUrl, params);
}

function update(id,params) {
    return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function getRounds(id) {
    return fetchWrapper.get(`${baseUrl}/getRounds/${id}`);
}

function remove(id) {
    return fetchWrapper.delete(`${baseUrl}/${id}`);
}