import React from 'react'

const icon = (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
    </svg>
)

export default function AddIcon({ clickHandle, animated }) {
    return (
        <div className={`inline-icon${animated ? ` animated` : ``}`} onClick={(e) => {
            e.stopPropagation();
            clickHandle()
        }}>
            {icon}
        </div>
    )
}
